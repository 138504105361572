import React from 'react';
import './App.css';
import NavBar from './components/navBar';

import HouseWrapper from './components/houseWrapper';
import FloorplanWrapper from './components/floorplanWrapper';
import LoginComponent from './components/LoginComponent';

import Cookies from 'universal-cookie';

class App extends React.Component {

    constructor(props) {
        super(props);
        const outer = this
        this.state = {
            //serverUrl: "http://192.168.1.131:8080",
            serverUrl: "https://hpv266.controlr.io",
            serverKey: "",
            loading: true,
            home: 'En eller anden placeholder',
            navigation: [],
            baseFloorUrl: "",
            functions: {
                refresh: function () {
                    outer.getData(this.state.serverKey)
                },
                action: function (url, value) {
                    outer.action(url, value)
                }
            },
            floorplan: [],
            selectedFloorplanIndex: 0
        }
        this.action = this.action.bind(this);
        this.getData = this.getData.bind(this);
        this.selectFloor = this.selectFloor.bind(this);
    }

    selectFloor(newFloorplan) {
        this.setState({
            floorplan: newFloorplan
        })
    }

    action(url, value) {
        const callUrl = value === undefined ? this.state.serverUrl + url : this.state.serverUrl + url + "?value=" + value

        const extraHeaders = new Headers();
        extraHeaders.set("X-server-key", this.state.serverKey)

        fetch(callUrl, {headers: extraHeaders})
            .then(res => res.json())
            .then(
                (result) => {
                    console.log("action finished");
                    console.log(result);
                    this.setState({
                        loading: false,
                        floorplans: result.floorplan,
                        navigation: result.navigation,
                        home: result.home,
                        baseFloorUrl: result.baseFloorUrl
                    });

                },
                (error) => {
                    console.log("Error!!")
                }
            )
    }

    handleErrors(response) {
        if (!response.ok) {
            console.log(response.statusText);
            const cookies = new Cookies()
            cookies.remove("key")
            window.location.reload(false);
        }
        return response;
    }

    getData(key) {
        const extraHeaders = new Headers();
        extraHeaders.set("X-server-key", key)
        fetch(this.state.serverUrl + "/state", {
            headers: extraHeaders,
        })
            .then(this.handleErrors)
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result)
                    this.setState({
                        loading: false,
                        floorplans: result.floorplan,
                        selectedFloorplanIndex: 0,
                        navigation: result.navigation,
                        home: result.home,
                        baseFloorUrl: result.baseFloorUrl,
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    console.log(error)
                }
            )
    }

    componentDidMount() {
        const cookies = new Cookies();
        let serverKey = cookies.get('key');
        if(serverKey != undefined) {
            this.setState({serverKey: serverKey})
            this.getData(cookies.get('key'));
        }
    }


    render() {
        const cookies = new Cookies();

        let serverKey = cookies.get('key');
        console.log("key: " + serverKey);

        const selectFloorplanIndex = newIndex => e => {
            console.log("new floor selected: " + newIndex)
            this.setState({
                selectedFloorplanIndex: newIndex
            })
        };

        return serverKey === undefined ?
            (<LoginComponent postUrl={this.state.serverUrl + "/session"}/>)
            :
            (<div>
                {this.state.loading ? <div>{this.state.home} Loading</div> : <div className={"homeautomation"}>
                    <div className={"home-wrapper"}>

                        <img alt={"Background glow"} className={"backgroundGlow"}
                             src="https://dpl.gg/ihc/sprites/background_glow.png"/>{}
                        <FloorplanWrapper
                            home={this.state.home}
                            floorplans={this.state.floorplans}
                            functions={this.state.functions}
                            selectFloor={selectFloorplanIndex}
                            selectedFloorplan={this.currentFloorplan()}
                        />
                        <HouseWrapper floorplan={this.currentFloorplan()}
                                      home={this.state.home}
                                      functions={this.state.functions}
                                      baseFloorUrl={this.state.baseFloorUrl}
                        />
                    </div>
                    <NavBar navigation={this.state.navigation} functions={this.state.functions}/>
                </div>
                }

            </div>

        );
    }

    currentFloorplan() {
        return this.state.floorplans[this.state.selectedFloorplanIndex];
    }
}

export default App