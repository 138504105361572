import React, {Component} from 'react';


class RoomComponent extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        let hasLights = this.props.data.lights !== undefined
        let roomBrightness = hasLights ? Math.max(...this.props.data.lights.map(light => light.brightness)) : 0;
        let light = hasLights ? (
            <img alt={'Overview of ' + this.props.data.name} className={'sprite ' + this.props.data.name}
                 key={this.props.data.name} src={this.props.data.lights[0].icon}
                 style={{opacity: roomBrightness + '%'}}/>) : <span/>

        return (
            <div className={"room " + this.props.data.name}>
                {light}
            </div>
        );
    }
}

export default RoomComponent;