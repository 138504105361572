import React, {Component} from 'react';

class WindowSection extends Component {

    constructor(props) {
        super(props);
        this.action = this.action.bind(this);
    }

    action(e, url, value) {
        e.preventDefault();
        this.props.functions.action(url, value)
    }

    render() {
        let windows = this.props.currentRoom;
        if (windows !== undefined) {
            return <div className={"popUpSection"}>
            <div>
                <div className="popUpSectionHeader">Vinduer</div>
                {this.props.currentRoom.map((window, index) => (
                    <div key={"window-" + window.name} className={"popUpSectionRow"}>
                        <div className={"popUpSectionTitle"}>
                            {window.name}
                        </div>
                        <div className={"popUpSectionInput"}></div>
                        {
                            (window.actions["OPEN"] !== undefined ? <a className={"action"} href="#" onClick={
                                (e) => this.action(e, window.actions["OPEN"])
                            }>Åben</a> : <span/>)
                        }
                        {
                            (window.actions["CLOSE"] !== undefined ? <a className={"action"} href="#" onClick={
                                (e) => this.action(e, window.actions["CLOSE"])
                            }>Luk</a> : <span/>)
                        }
                    </div>
                ))}
            </div>
            </div>
        } else {
            return (<div></div>)
        }
    }
}

export default WindowSection;