import React, {Component} from 'react';
import ReactDOM from 'react-dom'
import LightSection from "../popup/lightSection";
import AlarmSection from "./AlarmSection";

class SidebarComponent extends Component {

    constructor(props) {
        super(props);
        this.el = document.createElement('div');
        this.el.className = "sidebarContainer";
    }

    componentDidMount() {
        document.body.appendChild(this.el);
    }

    componentWillUnmount() {
        document.body.removeChild(this.el);
    }

    render() {
        return ReactDOM.createPortal(
            this._render(),
            this.el,
        );
    }

    _render() {
        let currentNav = this.props.currentNav;

        let alarm = currentNav !== undefined && currentNav.id === "alarm" ?
            <AlarmSection alarm={this.props.currentNav.thing} functions={this.props.functions}/> : <span/>

        let sidebarComponent = currentNav !== undefined ?
            /*SIDEBAR OPEN*/
            <div onClick={this.props.closeSidebar} key={"sidebarOverlay"} className={"sidebarOverlay"}>
                <div key={"sidebar"} className={"sidebar sidebarOut"}>
                    <div key={"sidebarRoomName"} className={"sidebarRoomName"}>{currentNav.title}</div>
                    <div key={"sidebarScrollContainer"} className={"sidebarScrollContainer"}>
                        {alarm}
                    </div>
                </div>
            </div>
            :
            /*SIDEBAR CLOSED*/
            <div key={"sidebarOverlay"} className={"sidebarOverlay sidebarOverlayHidden"}>
                <div key={"sidebar "} className={"sidebar"}>
                </div>
            </div>

        return (
            <div>
                {sidebarComponent}
            </div>
        );
    }
}

export default SidebarComponent;