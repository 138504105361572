import React, {Component} from 'react';

class FloorplanWrapper extends Component {
    render() {
        const floorplanSelector =
            this.props.floorplans.map((floorplan, index) => (
                <div onClick={this.props.selectFloor(index)} key={floorplan.title}
                     className={"floorplanTitle " + (floorplan.identifier === this.props.selectedFloorplan.identifier ? "selected" : "")}>{floorplan.title}</div>
            ))

        return (
            <div className={"floorplanWrapper"}>
                <div className={"floorplanHome"}>{this.props.home}</div>
                <div className={"floorplanTitleWrapper"}>
                    {floorplanSelector}
                </div>
            </div>
        );
    }
}

export default FloorplanWrapper;