import React, {Component} from 'react';
import Slider from 'react-input-slider';

function compareObjects(a, b) {
    if (a === b) return true;

    if (typeof a != 'object' || typeof b != 'object' || a == null || b == null) return false;

    let keysA = Object.keys(a), keysB = Object.keys(b);

    if (keysA.length !== keysB.length) return false;

    for (let key of keysA) {
        if (!keysB.includes(key)) return false;

        if (typeof a[key] === 'function' || typeof b[key] === 'function') {
            if (a[key].toString() !== b[key].toString()) return false;
        } else {
            if (!compareObjects(a[key], b[key])) return false;
        }
    }

    return true;
}

class LightSection extends Component {

    constructor(props) {
        super(props);
        this.action = this.action.bind(this);
        this.setBrightness = this.setBrightness.bind(this);

        this.state = {
            lights: this.props.currentRoom,
            loading: false
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.state.loading && !compareObjects(prevProps, this.props)) {
            this.setState({loading: false})
        }
    }

    loading() {
        this.setState({
            loading: true
        })
    }

    action(e, url, value) {
        this.loading();
        e.preventDefault();
        this.props.functions.action(url, value)
    }

    setBrightness(url, value) {
        this.loading();
        this.props.functions.action(url, value)
    }

    render() {
        let lights;
        console.log(this.state.loading)
        if (this.props.currentRoom !== undefined && !this.state.loading) {
            lights = <div>
                <div className="popUpSectionHeader">Lys</div>
                {this.props.currentRoom.map((light, index) => (
                    <div key={"light-" + light.name} className={"popUpSectionRow"}>
                        <div className={"popUpSectionTitle"}>
                            {light.name}</div>
                        <div className={"popUpSectionInput"}>
                            {light.brightness} %
                        </div>
                        {
                            (light.actions["ON"] !== undefined ? <a className={"action"} href="#" onClick={
                                (e) => this.action(e, light.actions["ON"])
                            }>Tænd</a> : <span/>)
                        }
                        {
                            (light.actions["OFF"] !== undefined ? <a className={"action"} href="#" onClick={
                                (e) => this.action(e, light.actions["OFF"])
                            }>Sluk</a> : <span/>)
                        }
                        {
                            (light.actions["DIM"] !== undefined ?
                                <Slider
                                    axis="x"
                                    xstep={1}
                                    xmin={0}
                                    xmax={100}
                                    x={light.brightness}
                                    onDragEnd={({x}) => {
                                        this.setBrightness(light.actions["DIM"], this.state.lights[index].brightness)
                                    }}
                                    onChange={({x}) => this.setState(prevState => {
                                        let lights = Object.assign({}, prevState.lights);
                                        lights[index].brightness = x;
                                        return [lights];
                                    })}
                                /> : <span/>)
                        }
                    </div>
                ))}</div>;
        } else if(this.state.loading) {
            lights = <div className="lds-default">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>;
        } else {
            lights = <span/>;
        }

        return (<div className={"popUpSection"}>{lights}</div>)
    }
}

export default LightSection;