import React, {Component} from 'react';

class TemperatureSection extends Component {

    constructor(props) {
        super(props);
        this.action = this.action.bind(this);
    }

    action(e, url, value) {
        e.preventDefault();
        this.props.functions.action(url, value)
    }

    render() {
        let temperature = this.props.currentRoom;
        console.log(temperature)

        if (temperature !== undefined) {

            return (<div className={"popUpSection"}>
                <div>
                    <div className="popUpSectionHeader">Klima</div>
                    <div>Temperatur: {temperature.temperature}°</div>
                    <div>Luftfugtighed: {temperature.humidity}%</div>
                </div>
            </div>)
        } else {
            return (<div></div>)
        }
    }
}

export default TemperatureSection;