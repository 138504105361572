import React, {Component} from 'react';
import Slider from 'react-input-slider';

class AlarmSection extends Component {

    constructor(props) {
        super(props);
        this.action = this.action.bind(this);
    }

    action(e, url, value) {
        console.log("running alarm action: " + url)
        e.preventDefault();
        this.props.functions.action(url, value)
    }

    render() {
        console.log(this.props.alarm)
        let armed = this.props.alarm.armed ? "Tilsluttet" : "Slået fra"
        return (<div>
                <div className={"alarmStatusContainer"}>
                    Alarm status: <span className={"alarmStatusIndicator"}>{armed}</span>
                </div>
                <div>
                    {
                        (this.props.alarm.actions["ARM"] !== undefined ?
                            <a className={"sidebarAction"} href="#" onClick={
                                (e) => this.action(e, this.props.alarm.actions["ARM"])
                            }>Armér</a> : <span/>)
                    }
                </div>
                <div>

                    {
                        (this.props.alarm.actions["DISARM"] !== undefined ?
                            <a className={"sidebarAction"} href="#" onClick={
                                (e) => this.action(e, this.props.alarm.actions["DISARM"])
                            }>Disarmér</a> : <span/>)
                    }
                </div>
            </div>
        )
    }
}

export default AlarmSection;