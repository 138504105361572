import React, {Component} from 'react';
import LightSection from "./lightSection";
import MusicSection from "./musicSection";
import ReactDOM from 'react-dom'
import WindowSection from "./WindowSection";
import TemperatureSection from "./TemperatureSection";

class popupComponent extends Component {

    constructor(props) {
        super(props);
        this.el = document.createElement('div');
        this.el.className = "popupContainer";
    }

    componentDidMount() {
        document.body.appendChild(this.el);
    }

    componentWillUnmount() {
        document.body.removeChild(this.el);
    }

    render() {
        return ReactDOM.createPortal(
            this._render(),
            this.el,
        );
    }

    preventClose(e) {
        e.preventDefault()
    }

    _render() {
        return (
            <div onClick={this.props.closePopup} className={"popUpOverlay"}>
                <div onClick={e => e.stopPropagation()} className={"popUp"}>
                    <div className={"popUpRoomName"}>{this.props.currentRoom.name}</div>
                    <div className={"sectionScrollContainer"}>
                        <LightSection currentRoom={this.props.currentRoom.lights} functions={this.props.functions}/>
                        <WindowSection currentRoom={this.props.currentRoom.windows} functions={this.props.functions}/>
                        <MusicSection currentRoom={this.props.currentRoom.music} functions={this.props.functions}/>
                        <TemperatureSection currentRoom={this.props.currentRoom.temperature} functions={this.props.functions}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default popupComponent;