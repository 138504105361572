import React, {Component} from 'react';
import Cookies from "universal-cookie";

class LoginComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {key: ''};

        this.handleKeyChange = this.handleKeyChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async postData(url = '', data = {}) {
        // Default options are marked with *
        const response = await fetch(url, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'no-cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'omit', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        });
        return response // parses JSON response into native JavaScript objects
    }

    handleSubmit(event) {
        const cookies = new Cookies();
        cookies.set('key', this.state.key, {path: '/', expires: new Date(Date.now()+31536000000)});
    }

    handleKeyChange(event) {
        this.setState({key: event.target.value});
    }

    render() {
        return (
            <div className={"loginContainer popUp"}>
                <form onSubmit={this.handleSubmit}>
                    <label>
                        <p>Key</p>
                        <input type="password" onChange={this.handleKeyChange}/>
                    </label>
                    <div>
                        <button type="submit">Submit</button>
                    </div>
                </form>
            </div>
        );
    }
}

export default LoginComponent;