import React, {Component} from 'react';
import RoomComponent from './roomComponent';
import ImageMapper from "react-image-mapper";
import PopupComponent from "./popup/popupComponent";

const popupContainer = document.getElementById('popupContainer');


class HouseWrapper extends Component {

    constructor(props) {
        super(props);
        this.state = {
            imageMapperWidth: 0,
            currentRoomIndex: undefined,
            showPopup: false,
        };

        this.getImageWidth = this.getImageWidth.bind(this);
        this.roomClick = this.roomClick.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }

    getImageWidth({target: img}) {
        this.setState({
            imageMapperWidth: img.offsetWidth
        })
    }

    closePopup() {
        this.setState({
            currentRoomIndex: undefined
        })
    }

    roomClick(area) {
        console.log("room index: " + this.props.floorplan.rooms.findIndex(room => room.name === area.name))
        this.setState({
            currentRoomIndex: this.props.floorplan.rooms.findIndex(room => room.name === area.name),
        });
    }

    render() {
        console.log(this.props.baseFloorUrl)
        let popup = this.state.currentRoomIndex !== undefined ?
            <PopupComponent closePopup={this.closePopup}
                            currentRoom={this.props.floorplan.rooms[this.state.currentRoomIndex]}
                            functions={this.props.functions}/> : null
        return (
            <div className={"houseWrapperWrapper"}>
                {popup}
                <div className={"houseWrapper"}>
                    <div className={"houseWrapperCenter"}>
                        <div className="houseBaseFaded">
                            {/*Get max-width of image here to determine sizing for Lightcomponent*/}
                            <img alt={"Image of the floorplan for " + this.props.home} onLoad={this.getImageWidth}
                                 className={"houseBase"}
                                 src={this.props.baseFloorUrl}/>{}
                        </div>
                        <div className="">
                            {/*Get max-width of image here to determine sizing for Lightcomponent*/}
                            <img alt={"Image of the floorplan for " + this.props.home} onLoad={this.getImageWidth}
                                 className={"houseBase"}
                                 src={this.props.floorplan.url}/>{}
                        </div>

                        {this.props.floorplan.rooms.map(room => (
                            <RoomComponent key={room.name}
                                           coords={room.coords}
                                           scale={this.state.imageMapperWidth}
                                           data={room}
                                           showPopup={this.state.showPopup}
                                           functions={this.props.functions}
                            />
                        ))
                        }

                        <div className={"imageMapperPosition"}>
                            <ImageMapper
                                onClick={area => this.roomClick(area)}
                                imgWidth={this.props.floorplan.floorplanWidth}
                                width={this.state.imageMapperWidth}
                                position={"absolute"}
                                src={this.props.floorplan.url}
                                map={{
                                    name: "my-map",
                                    areas: this.props.floorplan.rooms.map(room => (
                                        {name: room.name, shape: "poly", coords: room.coords}
                                    ))
                                }}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HouseWrapper;