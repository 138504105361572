import React, {Component} from 'react';


class LightSection extends Component {

    constructor(props) {
        super(props);

    }

    render() {
        let music = this.props.currentRoom;
        if (music !== undefined) {
            return (
                <div className={"popUpSection"}>
                    <div className="popUpSectionHeader">Musik</div>
                    <div key="music-section" className={"popUpMusicSection"}>
                        <div className="musicAlbumArtworkContainer">
                            <img src={music.albumArtwork} className="musicAlbumArtwork"/>
                        </div>
                        <div className="musicNowPlayingContainer">
                            <div className="musicSong">
                                ♫ {music.song} </div>
                            <div className="musicArtist">
                                {music.artist}</div>
                            <div className="musicTime">
                                <div className="musicIndicator"
                                     style={{"left": (music.progress_ms / music.duration_ms) * 100 + "%"}}></div>
                                <div className="musicProgress"
                                     style={{"width": (music.progress_ms / music.duration_ms) * 100 + "%"}}></div>
                                <div className="musicDuration"></div>
                            </div>
                        </div>
                        <div className="musicControlsContainer">
                            <div className="controlsLine1">(-) P (-)</div>
                            <div className="controlsLine2">Shuffle / Repeat</div>

                        </div>
                    </div>

                </div>

            );
        } else {
            return (<div></div>)
        }
    }
}

export default LightSection;