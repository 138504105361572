import React, {Component} from 'react';
import ReactDOM from "react-dom";
import SidebarComponent from "./sidebar/sidebarComponent";

class NavBar extends Component {

    constructor(props) {
        super(props);
        this.state={
            currentNav: undefined,
        };

        this.navClick = this.navClick.bind(this);
        this.closeSidebar = this.closeSidebar.bind(this);
    }

    closeSidebar() {
        this.setState({
            currentNav: undefined
        })
    }

    navClick(clickedNav) {
        this.setState({
            currentNav: this.props.navigation.filter(navFromServer => navFromServer.title === clickedNav.title)[0],
        });
    }

    render() {
        let sidebar = <SidebarComponent closeSidebar={this.closeSidebar} currentNav={this.state.currentNav} functions={this.props.functions}/>

        return (
            <div className={"navBarWrapper"}>
                {sidebar}
                <div className="navBar">
                {this.props.navigation.map(navItem => (
                    <div onClick={navFromServer => this.navClick(navItem)} className={"navItem"} title={navItem.title} key={navItem.title}>
                        <div className={"navBarTitle"}>
                            {navItem.title}
                        </div>
                    </div>
                ))}
                </div>
            </div>
        );
    }
}

export default NavBar;